import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: '',
    };
  }

  toggleHamburger = () => {
    this.setState(
      {
        active: !this.state.active,
      },
      () => {
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            });
      }
    );
  };

  render() {
    return (
      <nav
        className="navbar is-transparent is-fixed-top is-brown"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <AnchorLink
              to="/#top"
              title="Logo"
              className="navbar-item stripped"
              stripHash
            >
              <Img fixed={this.props.logo} alt="Owl Path Logo" />
              <h1 className="title is-4 ml-2 has-text-white">Owl Path</h1>
            </AnchorLink>
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu is-brown ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-start has-text-centered">
              <AnchorLink
                to="/#about-the-work"
                title="About the Work"
                className="navbar-item stripped has-text-white"
                stripHash
              />
              <AnchorLink
                to="/#offerings"
                title="Offerings"
                className="navbar-item stripped has-text-white"
                stripHash
              />
              <AnchorLink
                to="/#megs-experience"
                title="Meg's Experience"
                className="navbar-item stripped has-text-white"
                stripHash
              />
              <AnchorLink
                to="/#classes-workshops-trainings"
                title="Classes, Workshops, & Trainings"
                className="navbar-item stripped has-text-white"
                stripHash
              />
            </div>
            <div className="navbar-end has-text-centered">
              <AnchorLink
                to="/#contact"
                title="Contact"
                className="navbar-item stripped has-text-white"
                stripHash
              />
            </div>
          </div>
        </div>
      </nav>
    );
  }
};

export default () => (
  <StaticQuery
    query={graphql`
      query {
        file(
          relativePath: { eq: "owl-path-logo-white-background-no-padding.png" }
        ) {
          childImageSharp {
            fixed(height: 32) {
              base64
              width
              height
              src
              srcSet
            }
          }
        }
      }
    `}
    render={(data) => <Navbar logo={data.file.childImageSharp.fixed} />}
  />
);
