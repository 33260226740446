import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer is-green">
        <div className="content has-text-centered">
          <Link to="/" title="Logo">
            <Img fixed={this.props.logo} alt="Emerge Permaculture" />
          </Link>
          <p className="mt-6">
            Designed and built by{' '}
            <a
              className="theme-link"
              href="https://www.kellenbusbysoftware.com"
              target="_blank"
              rel="noreferrer"
            >
              Kellen Busby Software
            </a>
          </p>
        </div>
      </footer>
    );
  }
};

export default () => (
  <StaticQuery
    query={graphql`
      query {
        file(
          relativePath: { eq: "owl-path-logo-white-background-no-padding.png" }
        ) {
          childImageSharp {
            fixed(height: 200) {
              base64
              width
              height
              src
              srcSet
            }
          }
        }
      }
    `}
    render={(data) => <Footer logo={data.file.childImageSharp.fixed} />}
  />
);
